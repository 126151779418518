/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars, faTimes, faChevronRight, faLongArrowAltLeft,
} from '@fortawesome/free-solid-svg-icons';

import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import './src/static/css/tailwind.css';

library.add(faBars, faTimes, faChevronRight, faLongArrowAltLeft);

const wrapRoot = ({ element }) => (
  <>
    { element }
  </>
);

export const wrapRootElement = wrapRoot;
